import {
    Observable,
} from 'rxjs';

import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';

export function initServicesFactory(authenticationFacade: AuthenticationFacade, configurationFacade: ConfigurationFacade): () => Observable<any> {
    return () => {
        authenticationFacade.forceTenantDuringInit();
        return configurationFacade.getConfiguration();
    };
}
