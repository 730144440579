import { Route } from '@angular/router';

import { PERMISSIONS } from './core/constants/permissions.constants';
import { AuthenticationGuard } from './core/guards/authentication/authentication.guard';
import { PublicAuthenticationGuard } from './core/guards/authentication/public-authentication.guard';
import { ssoGuard } from './core/guards/authentication/sso.guard';
import { AuthorisationGuard } from './core/guards/authorisation/authorisation.guard';
import { checkFeatureEnabledGuard } from './core/guards/check-feature-enabled/check-feature-enabled.guard';
import { GuestGuard } from './core/guards/guest/guest.guard';
import { initGuard } from './core/guards/init/init.guard';
import { initUnauthenticatedGuard } from './core/guards/init-unauthenticated/init-unauthenticated.guard';
import { MainLayoutComponent } from './core/layouts/main-layout/main-layout.component';
import { ErrorComponent } from './core/views/not-found/error.component';
import { FeatureFlag } from './shared/enums/feature-flag.enum';

export const ROUTES: Route[] = [
    {
        path: '',
        redirectTo: 'shares',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/routes'),
        canActivate: [
            initUnauthenticatedGuard,
            GuestGuard,
        ],
    },
    {
        path: 'callback',
        loadChildren: () => import('./callback/routes'),
    },
    {
        path: '',
        canActivate: [ssoGuard],
        children: [
            {
                path: '',
                component: MainLayoutComponent,
                canActivate: [
                    initGuard,
                ],
                children: [
                    {
                        path: '',
                        canActivate: [PublicAuthenticationGuard],
                        children: [
                            {
                                path: 'shares',
                                loadChildren: () => import('./shares/routes'),
                            },
                            {
                                path: 'profile',
                                loadChildren: () => import('./profile/routes'),
                            },
                        ],
                    },
                    {
                        path: '',
                        canActivate: [AuthenticationGuard],
                        children: [
                            {
                                path: 'contacts',
                                loadChildren: () => import('./contacts/routes'),
                            },

                            {
                                path: 'portfolios',
                                loadChildren: () => import('./portfolios/routes'),
                                data: {
                                    featureFlag: FeatureFlag.TECH_FRONT_PORTFOLIO_ENABLE,
                                    permissions: [PERMISSIONS.PORTFOLIO_MODULE_ACCESS],
                                },
                                canActivate: [checkFeatureEnabledGuard, AuthorisationGuard],
                            },
                        ],
                    },

                ],
            },
        ],
    },
    {
        path: 'unauthorised',
        data: {
            errorCode: '403',
        },
        component: ErrorComponent,
    },
    {
        path: '**',
        component: ErrorComponent,
    },
];
