import {
    inject,
    Injectable,
} from '@angular/core';

import { OAuthProvider } from '~/app/auth/services/oauth/common/oauth-provider';
import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';
import { ProviderOAuthType } from '~/app/shared/enums/authentication/authentication-provider.enum';

@Injectable({
    providedIn: 'root',
})
export class OauthTapicoProvider extends OAuthProvider {
    private configurationFacade : ConfigurationFacade = inject(ConfigurationFacade);

    constructor() {
        super(ProviderOAuthType.TAPICO);
    }

    getLoginUrl(accountServicerId: string): string {
        const oauthConfiguration = this.configurationFacade.getAuthenticationSnapshot()?.oauthConfiguration;
        if (!oauthConfiguration
            || !oauthConfiguration.redirectUrl
            || !oauthConfiguration.loginUrl
            || !oauthConfiguration.clientId
            || !oauthConfiguration.scope
            || !oauthConfiguration.authorityUrl) {
            return '';
        }
        const redirectUrl = decodeURIComponent(oauthConfiguration.redirectUrl)?.replace('{ACCOUNT_SERVICER_ID}', accountServicerId) ?? '';

        return oauthConfiguration.loginUrl
            .replace('{REDIRECT_URL}', redirectUrl)
            .replace('{CLIENT_ID}', oauthConfiguration.clientId)
            .replace('{SCOPE}', oauthConfiguration.scope)
            .replace('{AUTHORITY_URL}', oauthConfiguration.authorityUrl)
            .replace('{ACCOUNT_SERVICER_ID}', accountServicerId);
    }

    getLogoutUrl(): string {
        const oauthConfiguration = this.configurationFacade.getAuthenticationSnapshot()?.oauthConfiguration;
        if (!oauthConfiguration?.logoutUrl) {
            return '/auth/login';
        }
        return oauthConfiguration.logoutUrl;
    }
}
