{
  "name": "envestboard-ui",
  "version": "1.52.19",
  "scripts": {
    "ng": "ng",
    "start": "npm run i18n:cache-busting && ng serve",
    "start-target": "npm run i18n:cache-busting && ng serve --configuration $BUILD_ENV",
    "build": "npm run i18n:cache-busting && ng build --configuration $BUILD_ENV",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "test:watch": "ng test --watch",
    "e2e": "ng e2e",
    "i18n:cache-busting": "node ./i18n-cache-busting.js",
    "compodoc:build:json": "compodoc -p ./tsconfig.json -e json -d .",
    "storybook:serve": "ng run envestboard-ui:storybook",
    "storybook:build": "npm run i18n:cache-busting && npm run compodoc:build:json && ng run envestboard-ui:build-storybook",
    "cypress:open": "cypress open",
    "cypress:run": "cypress run",
    "lint": "npm run i18n:cache-busting && ng lint",
    "lint:styles": "stylelint 'src/**/*.scss'",
    "prepare": "husky",
    "i18n:extract": "transloco-keys-manager extract",
    "i18n:find": "transloco-keys-manager find",
    "build-bundle-report": "ng build --configuration production --source-map && npm source-map-explorer dist/**/*.js --html source-map-explorer.html"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.3",
    "@angular/cdk": "^17.1.2",
    "@angular/common": "^17.1.3",
    "@angular/compiler": "^17.1.3",
    "@angular/core": "^17.1.3",
    "@angular/forms": "^17.1.3",
    "@angular/material": "^17.1.2",
    "@angular/material-date-fns-adapter": "^17.1.2",
    "@angular/platform-browser": "^17.1.3",
    "@angular/platform-browser-dynamic": "^17.1.3",
    "@angular/router": "^17.1.3",
    "@cypress/schematic": "^1.7.0",
    "@juggle/resize-observer": "^3.4.0",
    "@ngneat/input-mask": "^6.1.0",
    "@ngneat/transloco": "^6.0.4",
    "@ngneat/transloco-locale": "^5.1.2",
    "@ngneat/transloco-messageformat": "^5.0.0",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngxs/devtools-plugin": "^3.8.2",
    "@ngxs/logger-plugin": "^3.8.2",
    "@ngxs/router-plugin": "^3.8.2",
    "@ngxs/storage-plugin": "^3.8.2",
    "@ngxs/store": "^3.8.2",
    "@sentry/angular-ivy": "^7.100.1",
    "@sentry/tracing": "^7.100.1",
    "@types/file-saver": "^2.0.7",
    "@videogular/ngx-videogular": "^8.0.0",
    "angular-google-tag-manager": "^1.9.0",
    "angular-svg-icon": "^17.0.0",
    "date-fns": "^2.30.0",
    "echarts": "^5.4.3",
    "file-saver": "^2.0.5",
    "flag-icons": "^6.15.0",
    "flat-cjs-for-jest": "npm:flat@^5.0.2",
    "inputmask": "^5.0.8",
    "jwt-decode": "^4.0.0",
    "libphonenumber-js": "^1.10.55",
    "moize": "^6.1.6",
    "ngx-drag-scroll": "^17.0.1",
    "ngx-dropzone": "^3.1.0",
    "ngx-echarts": "^17.1.0",
    "ngx-image-cropper": "^7.2.1",
    "ngx-infinite-scroll": "^17.0.0",
    "ngx-skeleton-loader": "^9.0.0",
    "normalize.css": "^8.0.1",
    "ramda": "^0.29.0",
    "rxjs": "~7.8.1",
    "scrollbooster": "^3.0.2",
    "tslib": "^2.6.2",
    "uid": "^2.0.2",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-builders/jest": "^17.0.1",
    "@angular-devkit/build-angular": "^17.1.3",
    "@angular-eslint/builder": "~17.2.1",
    "@angular-eslint/eslint-plugin": "~17.2.1",
    "@angular-eslint/eslint-plugin-template": "~17.2.1",
    "@angular-eslint/schematics": "~17.2.1",
    "@angular-eslint/template-parser": "~17.2.1",
    "@angular/cli": "^17.1.3",
    "@angular/compiler-cli": "^17.1.3",
    "@angular/elements": "^17.1.3",
    "@commitlint/cli": "^18.6.0",
    "@commitlint/config-conventional": "^18.6.0",
    "@compodoc/compodoc": "^1.1.23",
    "@faker-js/faker": "^8.4.1",
    "@ngneat/transloco-keys-manager": "^3.8.0",
    "@semantic-release/changelog": "^6.0.3",
    "@semantic-release/exec": "^6.0.3",
    "@semantic-release/git": "^10.0.1",
    "@semantic-release/npm": "^11.0.2",
    "@storybook/addon-actions": "^7.6.14",
    "@storybook/addon-docs": "^7.6.14",
    "@storybook/addon-essentials": "^7.6.14",
    "@storybook/addon-viewport": "^7.6.14",
    "@storybook/angular": "^7.6.14",
    "@types/core-js": "^2.5.8",
    "@types/inputmask": "^5.0.7",
    "@types/jest": "^29.5.12",
    "@types/node": "^20.11.17",
    "@types/ramda": "^0.29.3",
    "@types/scrollbooster": "^3.0.3",
    "@typescript-eslint/eslint-plugin": "^6.21.0",
    "@typescript-eslint/parser": "^6.21.0",
    "@webcomponents/custom-elements": "^1.6.0",
    "babel-loader": "^9.1.3",
    "canvas": "^2.11.2",
    "cypress": "^13.6.4",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-import-resolver-typescript": "^3.6.1",
    "eslint-plugin-change-detection-strategy": "^0.1.4",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-jsonc": "^2.13.0",
    "eslint-plugin-modules-newlines": "^0.0.7",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-rxjs-angular": "^2.0.1",
    "husky": "^9.0.10",
    "jest": "^29.7.0",
    "jest-slow-test-reporter": "^1.0.0",
    "lint-staged": "^15.2.2",
    "orval": "^6.24.0",
    "prettier": "^3.2.5",
    "prettier-plugin-organize-attributes": "^1.0.0",
    "puppeteer": "^22.0.0",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "semantic-release": "^23.0.2",
    "source-map-explorer": "^2.5.3",
    "storybook": "^7.6.14",
    "stylelint": "^15.11.0",
    "stylelint-config-idiomatic-order": "^9.0.0",
    "stylelint-config-standard-scss": "^11.1.0",
    "stylelint-order": "^6.0.4",
    "stylelint-selector-bem-pattern": "^3.0.1",
    "typescript": "~5.2.2"
  },
  "resolutions": {
    "stylelint-order": "6.0.3"
  }
}
